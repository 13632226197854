$main-color: rgba(0, 0, 0, 0.6);
$info-block: rgba(0, 0, 0, 0.83);
$text: #000;
$yellow-btn: #ffc600;
$btn-color: #ed1c24;
$light: rgba(255, 255, 255, 0.84);
$border-grey: #cccccc;
$warning: #ff0000;
$footer: #000;
$red: #e74c3c;

$admin-bg-color: #F8F8F8;

$illustration-bg: url(../images/bg5.jpg);
$five-bg: url(../images/bg4.jpg);
//$three-bg: url(url);