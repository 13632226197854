.button {
	border: none;
	border-radius: 3px;
	@include font($text, 'Roboto', 600);
	text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		font-weight: 400;
	}
}

.info-block__btn {
	padding: 8px;
	background-color: $yellow-btn;
	font-size: 1.2em;
	font-weight: 600;

	@media (min-width: $screen-sm-min) {
		padding: 13px;
		font-size: 1.6em;
		font-weight: 400;
	}

	&:hover {
		background-color: $yellow-btn + 10;
	}
}

.call-btn {
	width: 100%;
	padding: 13px 20px;
	background-color: $btn-color;
	color: #fff;
	font-weight: 600;
	font-size: 1.2em;
	box-shadow: 0 4px 0 $btn-color - 30;

	@media (min-width: $screen-sm-min) {
		font-size: 2em;
	}

	&:hover {
		background-color: $btn-color + 10;
	}
}

.object__btn {
	padding: 13px;
	width: 100%;
	background-color: $text;
	border-radius: 0;
	color: #fff;
	font-size: 1.2em;

	@media (min-width: $screen-sm-min) {
		font-size: 2em;
	}

	&:hover {
		background-color: $text + 10;
	}
}

.access-btn {
	padding: 5px 15px;
	background-color: $btn-color;
	font-size: 1.2em;

	@media (min-width: $screen-sm-min) {
		padding: 5px 60px;
		font-size: 1.6em;
	}
}