.logo {
	margin-top: 20px;
	text-align: center;

	@media (min-width: $screen-sm-min) {
		margin-top: 0;
		text-align: left;
	}

	img {
		width: 100%;

		@media (min-width: $screen-sm-min) {
			width: auto;
		}
	}
}

.share-top {
	padding-left: 0;
	list-style: none;
	padding-top:4px;

	@media (max-width: $screen-sm-min) {
		margin-bottom: 0;
		display:none;
	}

	@media (min-width: $screen-md-min) {
		margin-bottom: 0;
	}

	&__item {
		float: left;
		margin: 0 5px;
		transition: 0.2s ease;

		a {
			padding: 0px 23px 34px;
			background-image: url(../img/share.png);
		}

		&_fb {
			a {
				background-position-x: -55px;
			}
		}

		&_inst {
			a {
				background-position-x: -109px;
			}
		}

		&_tw {
			a {
				background-position-x: -163px;
			}
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

//Titles //

.title-wrap {
	margin: 15px 0 20px;
	background-color: $light;
	padding: 10px;

	@media (min-width: $screen-sm-min) {
		margin: 40px 0 20px;
		padding: 30px 20px;
	}

	&_small {
		padding: 20px;
		margin: 10px 0 15px;

		@media (min-width: $screen-sm-min) {
			margin: 10px 0 60px;
		}
	}
}

h1 {
	font-size: 10px;
}

.title {
	margin: 0;
	padding: 0 15px;
	@include font($text, 'Roboto', 600);
	font-size: 2em;
	text-transform: uppercase;
	z-index: 1;

	span {
		color: $warning;
	}

	@media (min-width: $screen-sm-min) {
		font-size: 4.2em;
	}

	&-wrapper {
		z-index: 1;
	}

	&_lower {
		text-transform: none;
		margin: 0;
	}

	&_white {
		color: #fff;
	}

	&_white-bg {
		padding: 10px 15px;
		background-color: #fff;
	}

	&_margin {
		margin-bottom: 20px;
	}
}

// Popup //

.popup {
	display: none;
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;

	&__form-wrapper {
		padding: 15px 0;
		position: relative;
	}

	&__form {
		position: absolute;
		top: 35%;
		width: 100%;
		transform: translateY(30%);
	}

	&__close {
		position: absolute;
		padding: 20px;
		top: 40px;
    	right: -10px;
		background-image: url(../img/close-icon.png);
		background-repeat: no-repeat;
		background-size: 100%;
	}
}

.popup_obj {
	display: none;
	position: fixed;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 9999;

	&-scroll {
		padding: 15px 0px;
		max-height: 85vh;
		overflow-y: auto;
		overflow-x: hidden;

		@media (min-width: $screen-sm-min) {
			padding: 0;
		}
	}

	&__wrap {
		margin-top: 7vh;
		background-color: #fff;

		@media (min-width: $screen-sm-min) {
			padding: 15px;
		}
	}

	&__close {
		position: absolute;
		padding: 20px;
		top: -9px;
    	right: -35px;
		background-image: url(../img/close-icon.png);
		background-repeat: no-repeat;
		background-size: 100%;

		@media (min-width: $screen-sm-min) {
			right: -40px;
		}
	}

	&__desc {
		padding: 15px 0;
		@include font($text, 'Roboto', 300);
		font-size: 1.2em;

		h2 {
			font-size: 16px;
			font-weight: bold;
		}
		@media (min-width: $screen-sm-min) {
			font-size: 1.3em;
		}
	}

	&__date,
	&__price {
		@include font($text, 'Roboto', 400);
		font-size: 1.2em;
		font-weight: bold;

		@media (min-width: $screen-sm-min) {
			font-size: 16px;
		}
	}


	&__img-overlay {
		height: 117px;

		@media (min-width: 390px) {
			height: 185px;
		}

		img {
			width: 100%;
		}
	}
	&__slide {
		width: 333.33px;
	}
	&__next,
	&__prev {
		display: block;
	}
}

// Swiper //

.swiper-button-prev,
.swiper-button-next {
	height: 45px;
	background-image: url(../img/arrows.png);
	background-size: 100%;
}

.swiper-button-prev {
	background-position: 0px 0px;
}

.swiper-button-next {
	background-position: 0px -134px;
}



.error-message {
	display: none;
	@include font($text, 'Roboto', 400);
	font-size: 1.2em;
	transform: skewY(-5.5deg);

	@media (min-width: $screen-md-min) {
		font-size: 1.6em;
	}

	&_desc {
		margin-top: 0;
		margin-bottom: 15px;
		transform: skewY(0deg);
	}
}

//Screen One//

#screen-one {
	padding-bottom: 20px;
	//background-image: url(../images/bg1.jpg);
	background-size: cover;
	background-position: center;
}

.screen-one__content {
	@media (min-width: $screen-sm-min) {
		padding-top: 80px;
	}
}

.subtitle {
	margin: 0;
	@include font($text, 'Roboto', 400);
	font-size: 2em;
	//text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		font-size: 3em;
	}

	&-wrap {
		margin-bottom: 20px;
		padding: 20px;
		background-color: $light;
	}
}

//Info Block//

.info-block {
	margin-top: 20px;
	margin-bottom: 5px;
	padding: 10px 20px;
	background-color: $info-block;

	@media (min-width: $screen-sm-min) {
		margin: 0;
	}

	&_transparent {
		margin-top: 0;
		padding: 0;
	    padding-top: 40px;
		background-color: transparent;
	}

	&__title {
		margin: 0;
		@include font(#fff, 'Roboto', 400);
		font-size: 1.2em;

		@media (min-width: $screen-sm-min) {
			font-size: 1.6em;
		}

		&_lifted {
			margin: 10px 0 20px;
		}
	}

	&__phone {
		@include font(#fff, 'Roboto', 400);
		font-size: 1.7em;
		line-height: 1.9em;

		@media (min-width: $screen-sm-min) {
			font-size: 2.5em;
		}

		&:hover {
			color: #fff;
			text-decoration: none;
		}
	}
}

// Screen Plan //

#screen-plan {
	padding: 20px 0;
	background-image: url(../images/bg2.jpg);
	background-size: cover;
	background-position: center;
}

.plan-list {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 10px;
	background-color: $light;
	color: $text;
	font-size: 1.4em;
	list-style: none;

	@media (min-width: $screen-sm-min) {
		font-size: 1.8em;
	}

	&__item {
		padding: 15px 0;
		width: 100%;

		@media (min-width: $screen-sm-min) {
			float: left;
			width: 50%;
		}
	}

	&__text {
		display: inline-block;
		width: 70%;

		@media (min-width: $screen-md-min) {
			width: auto;
		}
	}

	&__icon {
		margin: 0 20px 0 10px;
		padding: 10px 20px;
		background-size: 100%;
		background-repeat: no-repeat;

		&_site {
			background-image: url(../img/icon-site.png);
		}

		&_mail {
			background-image: url(../img/icon-mail.png);
		}

		&_adv {
			background-image: url(../img/icon-adv.png);
		}

		&_manage {
			background-image: url(../img/icon-manage.png);
		}

		&_video {
			background-image: url(../img/icon-video.png);
		}

		&_direct {
			background-image: url(../img/icon-direct.png);
		}

		&_banner {
			background-image: url(../img/icon-banner.png);
		}

		&_insta {
			background-image: url(../img/icon-insta.png);
		}

		&_leaflet {
			background-image: url(../img/icon-leaflet.png);
		}

		&_lp {
			background-image: url(../img/icon-lp.png);
		}

		&_doors {
			background-image: url(../img/icon-doors.png);
		}

		&_doc {
			background-image: url(../img/icon-doc.png);
		}
	}
}

.bonus {
	margin-bottom: 20px;
	@include font($warning, 'Roboto', 400);
	font-size: 1.6em;
	text-decoration: underline;
	text-transform: uppercase;

	@media (min-width: $screen-sm-min) {
		font-size: 2.5em;
	}
}

// Screen Work //

#screen-work {
	padding: 20px 0;
	background-image: url(../images/bg3.jpg);
	background-size: cover;
	background-position: center;

	@media (min-width: $screen-sm-min) {
		background-size: cover;
	}
}

.work {
	&__text {
		margin-bottom: 20px;
		padding: 10px;
		background-color: $light;
		@include font($text, 'Roboto', 400);
		font-size: 1.4em;

		@media (min-width: $screen-sm-min) {
			margin-top: 20px;
			padding: 20px;
			font-size: 2em;
		}
	}
}

//Screen Two//

#screen-two {
	padding: 20px 0;
	background-color: #F8F8F8;
}

.feature {
	margin: 0px auto 20px;;

	&__img {
		margin: 0 auto;
		margin-bottom: 10px;
		width: 100px;
		height: 100px;
		background-color: $text;
		border-radius: 100%;

		@media (min-width: $screen-sm-min) {
			width: 146px;
			height: 146px;
		}

		img {
			margin-top: 19%;
			width: 60%;
		}
	}

	&__text {
		@include font($text, 'Roboto', 400);
		font-size: 1.2em;

		@media (min-width: $screen-sm-min) {
			font-size: 2em;
		}
	}
}

.feature-wrap {
	padding: 0;
}

// Screen Three //

#screen-three {
	padding: 20px 0;
	//background-image: $three-bg;
	background-size: cover;
	background-position: 0 50%;

	@media (min-width: $screen-sm-min) {
		padding: 30px 0;
		background-size: 100%;
	}
}

.video {
	margin: 20px auto 20px;
	width: 290px;
    height: 165px;

	@media (min-width: $screen-sm-min) {
		width: 560px;
		height: 315px;
		background-size: 100%;
	}

	&_half {
		width: 290px;
	    height: 165px;

		@media (min-width: $screen-md-min) {
			width: 560px;
			height: 315px;
			background-size: 100%;
		}
	}
}

// Screen Four //

#screen-four {
	padding: 15px 0;

	@media (min-width: $screen-sm-min) {
		padding: 30px 0;
	}
}

.object {
	margin: 15px 0;
	box-shadow: 0 2px 5px #939393;

	@media (min-width: $screen-sm-min) {
		margin: 30px 0;
	}

	&__img {
		min-height: 154px;
		max-height: 200px;
		overflow: hidden;

		@media (min-width: $screen-sm-min) {
			height: 210px;
		}

		img {
			width: 100%;
			//min-height: 100%;
		}
	}

	&__info {
		padding: 10px;
		height: 200px;
		@include font($text, 'Roboto', 400);
		font-size: 1.2em;

		@media (min-width: $screen-sm-min) {
			height: 300px;
			font-size: 2em;
		}
	}

	&__description {
		margin-top: 15px;
		font-weight: 300;

		@media (min-width: $screen-sm-min) {
			font-size: 0.9em;
		}
	}
}

.warning {
	display: block;
	padding: 0 15px;
	@include font($warning, 'Roboto', 400);
	font-size: 1.2em;

	@media (min-width: $screen-sm-min) {
		padding: 0;
		font-size: 2em;
	}
}

.info {
	display: block;
	padding: 0 15px;
	margin: 15px 0 20px;
	@include font($text, 'Roboto', 400);
	font-size: 1.2em;

	@media (min-width: $screen-sm-min) {
		padding: 0;
		font-size: 2em;
	}

	&_white-bg {
		margin-top: -25px;
		padding: 30px 15px 7px;
		background-color: $light;
		z-index: 0;
	}
}

// Screen Five //

#screen-five {
	padding: 20px 0;
	background-image: $five-bg;
	background-size: cover;
	background-position: 0 20%;

	@media (min-width: $screen-sm-min) {
		padding: 30px 0;
	}
}

.reviews,
.examples {
	&__container {
		margin-bottom: 20px;
	}

	&__wrapper {
		img {


			min-width: 100%;
			height: 100%;
		}
	}

	&__slide {
		background-color: #EDEDED;
		width: 220px;
		//margin: 0 80px;

		@media (min-width: $screen-sm-min) {
			width: 260px;
		}
	}
	&__video {
		width: 220px;

		@media (min-width: $screen-sm-min) {
			width: 700px;
		}
	}

	&__image-overlay {
		width: 100%;
		height: 200px;
		overflow: hidden;
	}

	&__text {
		margin: 0;
		padding: 15px;
		height: 195px;
		background-color: #fff;
		@include font($text, 'Roboto', 300);
		font-size: 1.6em;
	}

	&__next {
		//background-image: url(../img/arrows.png);
	}

	&__prev {
		//background-image: url(../img/arrows.png);
	}
}

// Screen examples //

#screen-examples {
	padding: 20px 0;
	background-image: url(../images/bg3.jpg);
	background-size: cover;
	background-position: center;
}

.examples {
	&__container {
		margin: 0;
		margin-top: 20px;
	}

	&__text {
		margin: 0;
		height: auto;
	}

	&__slide {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	}

	&__btn {
		width: 100%;
		padding: 15px;
		background-color: $btn-color;
		border: none;
		border-radius: 0;
		@include font(#fff, 'Roboto', 400);
		font-size: 1.6em;
		text-transform: uppercase;
		outline: none;

		&:hover {
			background-color: $btn-color - 20;
		}
	}
}

.description-open {
	cursor: pointer;
}

// Guarantee //

#screen-guarantee {
	padding: 20px 0;
}

.guarantee {
	padding: 20px 0 0;

	&__img {
		margin: 0 auto 10px;
	    width: 85px;

	    @media (min-width: $screen-sm-min) {
			margin-top: 0;
			width: 158px;
		}
	}

	&__text {
		display: block;
		margin-bottom: 20px;
		@include font($text, 'Roboto', 400);
		font-size: 1.2em;

		@media (min-width: $screen-sm-min) {
			font-size: 1.6em;
		}
	}
}

// Screen Six //

.map {
	width: 100%;
	height: 80vh;

	@media (min-width: $screen-sm-min) {
		height: 700px;
	}
}

.company {
	position: relative;
}

.company-map {
	position: absolute;
	width: 60%;
	bottom: 20vh;
	right: 20%;
	background-color: #fff;
	box-shadow: 5px 5px $border-grey;

	@media (min-width: $screen-sm-min) {
		width: 400px;
		bottom: 50%;
	}

	&__title {
		margin: 0;
		padding: 15px;
		background-color: $yellow-btn;
		@include font($text, 'Roboto', 400);
		font-size: 2em;
		text-align: center;

		@media (min-width: $screen-sm-min) {
			padding: 30px 60px;
			font-size: 4em;
		}
	}

	&__content {
		padding: 8px;

		@media (min-width: $screen-sm-min) {
			padding: 30px;
		}
	}

	&__target {
		float: left;
		width: 25%;
	}

	&__address {
		float: left;
		margin-left: 8px;
		padding-left: 8px;
		border-left: 2px solid $border-grey;
		@include font($text, 'Roboto', 400);
		font-size: 1.2em;

		@media (min-width: $screen-sm-min) {
			margin-left: 20px;
			padding-left: 20px;
			font-size: 2.2em;
		}
	}
}

// Screen Seven //

#screen-seven {
	padding: 15px 0;
	background-image: $illustration-bg;
	background-size: cover;
	background-position: center;

	@media (min-width: $screen-sm-min) {
		padding: 30px 0 50px;
	}
}

.illustration {
	position: relative;
	margin-bottom: 100px;
	@media (min-width: $screen-sm-min) {
		margin-bottom: 200px;
	}


	@media (min-width: $screen-md-min) {
		padding-right: 0;
		padding-left: 15px;
	}

	&__img {
		width: 80%;
	}

	&__info {
		padding: 10px;
		width: 85%;
		right: 8%;
		bottom: -49px;
		position: absolute;
		background-color: #fff;
		@include font($text, 'Roboto', 400);
		font-size: 1.6em;
		z-index: 10;


		@media (min-width: $screen-sm-min) {
			bottom: -38px;
			right: 5%;
		}

		@media (min-width: $screen-md-min) {
			bottom: -38px;
		}
	}

	&__title {
		position:absolute;
		width: 90%;
		bottom: -126px;
		background-color: $btn-color;
		@include font(#fff, 'Roboto', 400);
		font-size: 1.4em;
		text-transform: uppercase;
		padding: 45px 20px 30px;

		h2 {
			margin: 0;
			font-size: 1.1em;
		}
		@media (min-width: $screen-sm-min) {
			bottom: -220px;
			margin: 0 5%;
			font-size: 2.4em;
		}
		@media (min-width: $screen-md-min) {
			bottom: -199px;
		}
		@media (min-width: $screen-lg-min) {
			bottom: -199px;
		}


	}
}

.share-person {
	padding-left: 0;
	list-style: none;
	padding-top:4px;
	height: 46px;

	@media (max-width: $screen-sm-min) {
		margin-bottom: 0;
		display:none;
	}

	@media (min-width: $screen-md-min) {
		margin-bottom: 0;
	}

	&__item {
		float: left;
		margin: 0 5px;
		transition: 0.2s ease;

		a {
			width: 46px;
			height: 46px;
			background-image: url(../img/share.png);
			display:inline-block;
		}

		&_fb {
			a {
				background-position-x: -55px;
			}
		}

		&_inst {
			a {
				background-position-x: -109px;
			}
		}

		&_tw {
			a {
				background-position-x: -163px;
			}
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

// Form //

.form-wrap {
	margin-top: 85px;
	margin-bottom: 20px;

	@media (min-width: $screen-md-min) {
		padding-left: 0;
		margin: 0;
	}
}

.form {
	padding: 30px 15px;
	background-color: #fff;
	transform: skewY(5.5deg);

	@media (min-width: $screen-md-min) {
		padding: 40px 45px;
	    margin-top: 65px;
	}

	&__input {
		display: block;
		margin: 10px 0;
		padding: 10px;
		width: 100%;
		border: 1px solid $border-grey;
		@include font(#4C4C4C, 'Roboto', 300);
		font-size: 1.6em;
		text-align: center;
		transform: skewY(-5.5deg);

		@media (min-width: $screen-sm-min) {
			padding: 20px;
			font-size: 2em;
		}

		&:focus {
			outline: none;
		}
	}

	&__submit {
		padding: 10px;
		margin: 20px 0;
		background-color: $yellow-btn;
		box-shadow: 0 4px 0 $yellow-btn - 30;
		color: $text;
		transform: skewY(-5.5deg);
		white-space: normal;

		&:hover {
			background-color: $yellow-btn - 10;
		}

		@media (min-width: $screen-sm-min) {
			padding: 25px 20px;
		}
	}
}

.form_desc {
	padding: 0;
	padding-top: 15px;

	@media (min-width: $screen-sm-min) {
    	padding-top: 30px;
	}

	&__input {
		margin: 0;
		padding: 10px;
		width: 100%;
		border: 1px solid $border-grey;
		@include font(#4C4C4C, 'Roboto', 300);
		font-size: 1.6em;
	    margin-bottom: 10px;
		text-align: center;
		transform: skewY(0deg);

	    @media (min-width: $screen-sm-min) {
			font-size: 1.5em;
		}

		@media (min-width: $screen-md-min) {
			margin-bottom: 0;
		}

		&:focus {
			outline: none;
		}
	}

	&__submit {
		padding: 10px;
		font-size: 1.4em;
		white-space: normal;

		@media (min-width: $screen-sm-min) {
			font-size: 1.5em;
		}
	}
}

// Footer //

.footer {
	padding: 0 0 20px 0;
	background-color: $footer;
}

.share {
	padding-top: 30px;
	padding-left: 0;
	margin-bottom: 40px;
	list-style: none;

	@media (min-width: $screen-sm-min) {
		padding-top: 55px;
		margin-bottom: 0;
	}

	@media (min-width: $screen-md-min) {
		padding-top: 90px;
		margin-bottom: 0;
	}

	&__item {
		float: left;
		margin: 0 5px;
		transition: 0.2s ease;

		a {
			padding: 17px 23px;
			background-image: url(../img/share.png);
		}

		&_fb {
			a {
				background-position-x: -55px;
			}
		}

		&_inst {
			a {
				background-position-x: -109px;
			}
		}

		&_tw {
			a {
				background-position-x: -163px;
			}
		}

		&:hover {
			opacity: 0.8;
		}
	}
}

.company-info {

	&__img {
		width: 100%;

		@media (min-width: $screen-sm-min) {
			width: 150px;
			margin-top: 40px;
		}
	}

	&__agreement {
		display: inline-block;
		@include font(#fff, 'Roboto', 300);
		font-size: 1.4em;

		@media (min-width: $screen-sm-min) {
			display: block;
			margin: 5px 0;
		}

		&:hover {
			color: $light;
			text-decoration: none;
		}
	}
}
