#admin-panel {
	padding-bottom: 30px;
	min-height: 100vh;
	background-color: $admin-bg-color;
}

.panel {
	margin: 15px 0;
	border: none;

	&__head {
		background-color: #EDEDED;
		@include font(#727272 !important, 'Roboto', 400);
		font-size: 1.5em;
	}

	&-body {
		padding-bottom: 0;
	}

	&_transparent {
		//background-color: transparent;
		//border: 1px solid #E4E4E4;
		//box-shadow: none;
	}
}

.form {
	&__label {
		@include font(#727272, 'Roboto', 400);
		font-size: 1.3em;

		&_left {
			float: left;
			margin-right: 15px;
		}
	}

	&__textarea {
		height: 90px !important;
	}
}

.block {
	&_dashed {
		margin-bottom: 15px;
		background-color: #FCFCFC;
		border: 1px dashed #D6D6D6;
		border-radius: 5px;
	}
}

.block_dashed {
	&__inner {
		padding-top: 15px;
		border-bottom: 1px dashed #D6D6D6;

		&:last-child {
			border-bottom: none;
		}
	}
}

.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.new-obj-btn {
	margin-bottom: 15px;
}

.input_image {
	@include font(#727272, 'Roboto', 400);
	font-size: 1.3em;
	text-transform: uppercase;
}

.form-control {
	box-shadow: none;
}

// Objects //

.object_admin,
.review_admin {
	padding: 15px;
	margin-top: 5px;
	margin-bottom: 5px;

	&__image, {
		padding: 0;
		border-radius: 5px;
   		overflow: hidden;

		img {
			width: 100%;
		}
	}
}

.review_admin {
	&__text {
		padding: 15px;
		background-color: $admin-bg-color;
		border-radius: 4px;
		@include font(#727272, 'Roboto', 300);
		font-size: 1.6em;

		&::before {
			content: '';
			position: absolute;
			top: 25px;
			left: 4px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 12px 11px 12px 0;
			border-color: transparent $admin-bg-color transparent transparent;
		}
	}

	&__image {
		height: 75px;
	}
}

.object_admin {

	&__price,
	&__space {
		display: block;
		@include font(#727272, 'Roboto', 300);
		font-size: 1.6em;
	}

	&__description {
		padding: 15px;
		margin-top: 10px;
		background-color: $admin-bg-color;
		border-radius: 4px;
		@include font(#727272, 'Roboto', 300);
		font-size: 1.6em;
	}

	&__photo {
		position: relative;
		height: 150px;
		overflow: hidden;

		img {
			width: 100%;
			border-radius: 4px;
		}
	}

	&__delete {
		position: absolute;
		top: 0;
		right: 15px;
		padding: 5px;
		background-color: $red;
		//border: 1px solid #B8B8B8;
		border-top-right-radius: 3px;
		border-bottom-left-radius: 3px;
		@include font(#fff, 'Roboto', 300);
		font-size: 1.2em;
		text-transform: uppercase;

		&:hover {
			background-color: $red - 10;
			color: #fff;
			text-decoration: none;
		}
	}
}

.controls {
	padding: 0;

	&__list {
		text-align: right;
		list-style: none;
	}

	&__item {
		margin-bottom: 10px;
	}
}

.alert-info {
	margin: 0;
	margin-top: 15px;
	@include font(#31708f, 'Roboto', 300);
	font-size: 1.6em;
}

.btn-submit {
	margin-top: 15px;
}
