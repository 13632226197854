@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//@import 'bower_components/Swiper/dist/css/swiper';
//@import 'bower_components/animate.css/animate.min';
//@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
//@import 'bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker';
@import "all";
@import "partials/variables";
@import "partials/mixins";
@import "partials/buttons";
@import "partials/common";
@import "landing";
@import "admin";


body {
	margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    overflow-x: hidden;
	font-size: 10px;
}

html {
	overflow-x: hidden;
}