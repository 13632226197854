//Navbar//
.navigation-nav {
	float: none;
	margin: auto;
}

.nav > li > a:hover,
.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}

.navigation {
	display: none;
	min-height: 40px;
	background-color: #000;
	border: none;
	border-radius: 0;

	@media (min-width: $screen-sm-min) {
		display: block;
		min-height: 50px;
	}

	&_admin {
		background-color: #EDEDED;
		border-radius: 0;

		&__item a {
			@include font(#878787, 'Roboto', 400);
			font-size: 1.2em;
			text-transform: uppercase;

			&:hover {
				background-color: #EDEDED !important;
				color: #31708f;
			}
		}

		img {
			width: 20px;
			height: 20px;
			opacity: 0.4;

			&:hover {
				opacity: 0.6;
			}
		}
	}

	&__item {
		float: left;
		width: 33.3%;
		text-align: center;

		@media (min-width: $screen-sm-min) {
			float: none;
		}

		a {
			@include font(#fff, 'Roboto', 400);
			font-size: 1.5em;
			text-decoration: none;

			@media (min-width: $screen-sm-min) {
				font-size: 2em;
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.2) !important;
			}

			span {
				border-bottom: 1px solid #fff;
			}
		}
	}
}